//mui
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
//hooks & utils
import {useRef} from 'react';
import {defaultImageSrc, getProductPrice} from 'src/utils/productUtils';
import useVisible from 'src/hooks/useVisible';
import {getImageFormat} from 'src/utils/imageUtils';

export default function ProductsGridSelectionItem({
	product,
	handleRemoveItem,
	handleAddItem,
	checkAddedItemExists: isAdded,
}) {
	const {
		isVisible: variationMenuIsVisible,
		show: showVariationMenu,
		hide: hideVariationMenu,
		toggle: toggleVariationMenu,
	} = useVisible();
	const anchorTargetRef = useRef();
	const {id, name, quantity, main_image, variations, price, discount} = product;
	const image = getImageFormat(product.main_image, 'thumbnail');
	const totalVariationsQuantity = () => {
		let total = 0;
		if (variations.length) {
			variations.forEach((variation) => (total += variation.quantity));
		}
		return total;
	};

	const addItem = (productOrVariation, key, isVariation) => {
		if (isVariation)
			handleAddItem({
				// copy product meta attributes like image,infinite,name
				...product,
				// override using variation values (price,quantity...)
				...productOrVariation,
				price: productOrVariation.price ?? product.price,
				discount: productOrVariation.discount ?? (productOrVariation.price ? null : product.discount),

				name: `${name} - ${productOrVariation.name}`,
				// custom id so we can select/deselect item
				id: key,
				isVariation: true,
				// order item setup
				variation: productOrVariation.id,
				product: product.id,
				selectedQuantity: 1,
			});
		else
			handleAddItem({
				...productOrVariation,
				product: productOrVariation.id,
				isVariation: false,
				selectedQuantity: 1,
			});
	};

	return (
		<Stack
			width={'184px'}
			height={'304px'}
			border={'1px solid #E0E0E0'}
			borderRadius={2}
			overflow={'hidden'}
		>
			<img
				loading="lazy"
				src={image}
				alt="product"
				style={{objectFit: 'cover', width: '100%', height: '150px'}}
			/>
			<Box p={1} flexGrow={1}>
				<Tooltip title={name} placement={'top'}>
					<Typography noWrap>{name}</Typography>
				</Tooltip>
				<Typography variant="body2" color={'#646464'}>
					الكمية المتاحة:{' '}
					{product.infinite ? '∞' : variations.length ? totalVariationsQuantity() : quantity}
				</Typography>
				<Typography variant="body2" color={'#646464'}>
					السعر: {getProductPrice(product)}
				</Typography>
			</Box>
			<Stack direction={'row'} alignItems={'center'} p={1}>
				{variations.length != 0 ? (
					<>
						<Button
							variant="outlined"
							fullWidth
							ref={anchorTargetRef}
							onClick={() => showVariationMenu()}
						>
							الإختيارات
						</Button>
						<Menu
							onClose={() => hideVariationMenu()}
							anchorEl={anchorTargetRef.current}
							open={variationMenuIsVisible}
						>
							<List dense>
								{variations.map((variation) => {
									// we need this so that we aviod the case of product.id == variation.id
									const key = `variation-${variation.id}`;
									return (
										<ListItem key={key} disablePadding>
											<ListItemButton
												onClick={(e) => {
													const isChecked = !isAdded(key);
													if (isChecked) {
														addItem(variation, key, true);
													} else {
														handleRemoveItem(key);
													}
												}}
												disabled={!product.infinite && variation.quantity == 0}
											>
												<ListItemIcon>
													<Checkbox checked={isAdded(key)} />
												</ListItemIcon>
												<ListItemText
													primary={variation.name}
													secondary={`الكمية : ${product.infinite ? '∞' : variation.quantity}`}
												/>
											</ListItemButton>
										</ListItem>
									);
								})}
							</List>
						</Menu>
					</>
				) : (
					<Button
						variant="contained"
						fullWidth
						color={'secondary'}
						// color={!isAdded(id) ? 'secondary' : 'error'}
						onClick={() => {
							addItem(product, product.id, false);
							// if (!isAdded(id)) addItem(product, product.id, false);
							// else handleRemoveItem(id);
						}}
						disabled={!product.infinite && quantity == 0}
					>
						إضافة
						{/* {isAdded(id) ? 'حذف' : 'إضافة'} */}
					</Button>
				)}
			</Stack>
		</Stack>
	);
}
