import {DateTime} from 'luxon';
import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {askYesOrNo} from 'src/components/modals/ChoicesModal';
import useShippingProviders from 'src/pages/DeliveryMethods/useShippingProviders';
import {hideLoaderAction, showLoaderAction} from 'src/reducers/app/appActions';
import {showModalAction} from 'src/reducers/modal/modalActions';
import {
	confirmReceivePayment,
	deleteOrder,
	deleteOrderItems,
	getOrder,
	returnOrder,
	updateOrder,
} from 'src/utils/AdminApi';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {orderStatus} from 'src/utils/orderUtils';
import handleDarbErrors from 'src/utils/shipping-api/handleDarbErrors';
import {handleActionAccessError} from 'src/utils/shipping-api/handleShippingErrors';
import handleSkyexErrors from 'src/utils/shipping-api/handleSkyexErrors';
import {
	createShippment,
	deleteShippment,
	getShippmentById,
	getTimelines,
} from 'src/utils/shipping-api/shippingApiFactory';

const useOrderDetails = (id) => {
	/**
	 * @type {[import('src/types/contentTypes').ContentTypeAttributes<'orders'>, Function]}
	 */
	const [order, setOrder] = useState();
	const [shippingDataIsLoading, setShippingDataIsLoading] = useState(false);
	const [shipping, setShipping] = useState(null);
	const [itemToDelete, setItemToDelete] = useState(null);
	const [shouldCancelOrder, setShouldCancelOrder] = useState(false);
	const {data: shippingProviders = []} = useShippingProviders();

	const {data: shippingTimelines = []} = useQuery(
		['shippingLogs'],
		async () => {
			try {
				const res = await getTimelines(
					order?.delivery_info?.shippingProvider,
					order?.delivery_info?.shippingId,
				);
				return res.data.data;
			} catch (err) {
				return [];
			}
		},
		{
			enabled:
				!isNullOrEmpty(order) &&
				!isNullOrEmpty(order?.delivery_info?.shippingId) &&
				!isNullOrEmpty(shippingProviders) &&
				!shippingProviders
					.find((s) => s.id == order?.delivery_info?.shippingProvider)
					?.not_available_actions?.includes('logs'),
		},
	);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const formatDateString = (dateStr) => {
		const d = DateTime.fromISO(dateStr).toISO();
		let time = d.split('T')[1].split(':').slice(0, 2).join(':');
		let date = d.split('T')[0];
		return `${date}/${time}`;
	};

	const fetch = () => {
		getOrder(id)
			.then(async (res) => {
				setOrder(res);
				// if this order is linked with shippment then we will fetch shipping details and timelines
				if (res?.delivery_info?.shippingId) {
					await fetchShippingDetails(
						res?.delivery_info?.shippingProvider,
						res?.delivery_info?.shippingId,
					);
				}
			})
			.catch((err) => alert(err));
	};
	useEffect(() => {
		fetch();
	}, []);

	const fetchShippingDetails = async (provider, id) => {
		setShippingDataIsLoading(true);
		try {
			const result = await getShippmentById(provider, id);
			setShipping(result.data.data);
		} catch (err) {
			console.log('fail fetching shipping details', err);
		} finally {
			setShippingDataIsLoading(false);
		}
	};

	const updateOrderStatus = (status) => {
		updateOrder(order.uuid, {status})
			.then(() => {
				setOrder({...order, status});
			})
			.catch((err) => alert(err));
	};

	const receivePayment = (isReceived) => {
		if (order.is_cashier && isReceived) {
			// TODO send request to confirm payment receive
			confirmReceivePayment(order.id)
				.then(() => {
					fetch();
					toast.success('تم تأكيد استلام المبلغ بنجاح');
				})
				.catch((err) => toast.error('حدث خطأ غير متوقع'));
		} else {
			updateOrder(order.uuid, {payment_received: isReceived})
				.then(() => {
					if (isReceived) toast.success('تم تأكيد استلام المبلغ بنجاح');
					else toast.success('تم الغاء استلام المبلغ بنجاح');

					setOrder({...order, payment_received: isReceived});
				})
				.catch((err) => alert(err));
		}
	};

	const handleReturnOrder = async () => {
		let should_return_down_payment = false;
		if (order.down_payment > 0)
			should_return_down_payment = await askYesOrNo('هل تريد ارجاع قيمة العربون؟');
		else if (!window.confirm('هل انت متأكد؟')) return;

		console.log('should_return_down_payment', should_return_down_payment);

		return returnOrder(order.id, should_return_down_payment)
			.then(() => {
				toast.success('تم عملية الارجاع بنجاح');
				fetch();
			})
			.catch((err) => toast.error('حدث خطأ غير متوقع'));
	};

	const handleDeleteItemSubmit = async (e) => {
		e.preventDefault();
		try {
			const reason = e.target[0].value;
			await deleteOrderItems(itemToDelete, reason);
			setItemToDelete(null);
			window.location.reload();
		} catch (error) {
			alert(error);
		}
	};

	const onStatusChange = async (e) => {
		// show modal to enter reason for cancelling
		if (e.target.value === 'cancelled') return setShouldCancelOrder(true);
		//   update directly
		if (
			await askYesOrNo(`هل انت متأكد من تغير حالة الطلب الي '${orderStatus[e.target.value].label}'`)
		)
			updateOrderStatus(e.target.value);
	};

	const handleCancelOrder = async (e) => {
		e.preventDefault();
		try {
			const reason = e.target[0].value;
			updateOrder(order.uuid, {status: 'cancelled', reason})
				.then((res) => {
					setOrder({...order, status: 'cancelled', reason});
					setShouldCancelOrder(false);
				})
				.catch((err) => alert(err));
		} catch (error) {
			alert(error);
		}
	};

	const onDeleteShippingClicked = async () => {
		dispatch(showLoaderAction);
		const shippingId = order?.delivery_info?.shippingId;
		const shippingProvider = order?.delivery_info?.shippingProvider;
		let changes = {...order.delivery_info};
		delete changes.shippingId;
		delete changes.shippingProvider;

		try {
			// remove shippingId from order to unlink order from shipment
			await updateOrder(order.uuid, {
				delivery_info: changes,
			});
			setOrder({...order, delivery_info: changes});

			try {
				//NOTE: for some provider it's possible that this request fail
				await deleteShippment(shippingProvider, shippingId);
				toast.success('تم حذف الشحنة ');
			} catch (error) {
				toast.error('تعذر حذف الشحنة من شركة الشحن');
				// handleErrors(order?.delivery_info?.shippingProvider, error);
			}
		} catch (err) {
		} finally {
			dispatch(hideLoaderAction);
		}
	};
	/**
	 *
	 * @param {string} provider shipping company id
	 * @param {*} extraValues extra inputs provided from shipping company form
	 */
	const onSendShippmentClicked = async (provider, extraValues = {}) => {
		dispatch(showLoaderAction);
		try {
			const result = await createShippment(provider, {id: order.id}, extraValues);
			toast.success('تم انشاء الشحنة بنجاح');
			const changes = {
				...order.delivery_info,
				shippingId: result.data.data.shippingId,
				shippingProvider: provider,
			};

			setOrder({...order, delivery_info: changes});
			try {
				fetchShippingDetails(provider, result.data.data.shippingId);
			} catch (error) {
				//
			}
			// setShippingTimeLines(result.data);
		} catch (err) {
			console.log(err);
			// TODO we may send error to sentry from here
			handleErrors(provider, err);
		} finally {
			dispatch(hideLoaderAction);
		}
	};

	const manualShippmentLink = async (provider, number) => {
		const changes = {
			...order.delivery_info,
			shippingId: number,
			shippingProvider: provider,
		};
		updateOrder(id, {delivery_info: changes});
		setOrder({...order, delivery_info: changes});
		try {
			fetchShippingDetails(provider, number);
		} catch (error) {
			//
		}
	};

	const handleErrors = (provider, err) => {
		let [status, errorMessage] = handleActionAccessError(provider, err);
		// vetrina server return api validation errors before provider api
		if (status === 422 && !isNullOrEmpty(err?.response?.data.data?.errors)) {
			// show GlobalModal component
			const title = err.response.data.message;
			return dispatch(showModalAction(title, err.response.data.data.errors));
		}
		// provider server respond with errors, we will try to handle them, and if we can't we will show unexpected error + log it to sentry
		if (status === 424 && err.response.data?.data?.response) {
			if (provider === 'skyex') {
				const errors = handleSkyexErrors(err.response.data?.data?.response);
				if (errors.length > 0) {
					errorMessage = errors.join('\n');
				}
			}
			if (provider === 'darb') {
				const errors = handleDarbErrors(err.response.data?.data?.response);
				if (errors.length > 0) {
					errorMessage = errors.join('\n');
				}
			}
		}

		toast.error(errorMessage);

		if (status === 401 || status === 403 || status === 406)
			return navigate(`/shipping-methods/${provider}`);
	};

	const onDeleteOrder = async () => {
		await toast.promise(deleteOrder(order.uuid), {
			error: 'حدث خطا غير متوقع',
			success: 'تم حذف المنتج بنجاح',
			pending: 'يرجي الانتظار',
		});
		navigate('/orders', {replace: true});
	};

	const dublicate = () => {
		navigate(order.is_cashier ? '/orders/pos' : '/orders/create', {
			state: {
				order,
			},
		});
	};
	return {
		order,
		setOrder,
		refetch: fetch,
		shippingDataIsLoading,
		shipping,
		shippingTimelines,
		itemToDelete,
		setItemToDelete,
		shouldCancelOrder,
		setShouldCancelOrder,
		onSendShippmentClicked,
		onDeleteShippingClicked,
		handleCancelOrder,
		onStatusChange,
		handleDeleteItemSubmit,
		onDeleteOrder,
		dublicate,
		receivePayment,
		handleReturnOrder,
		manualShippmentLink,
	};
};

export default useOrderDetails;
