// react
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// material
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Card,
	Container,
	Switch,
	Divider,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import AccessControlWrapper from 'src/components/AccessControlWrapper';
import CustomFields from 'src/components/inputs/CustomFields';
import FormikWrapper from 'src/components/wrappers/FormikWrapper';
import ImageCropPicker from 'src/components/inputs/ImageCropPicker.js/ImageCropPicker';
import Picker from 'src/components/inputs/Picker';
import PublishSwitch from 'src/pages/products/common/PublishSwitch';
import SubmitButton from 'src/components/buttons/SubmitButton';
import AddPropertyValueModal from 'src/components/_dashboard/properties/AddPropertyValueModal';
import SelectPropertyModal from 'src/components/_dashboard/properties/SelectPropertyModal';
import {ScrollToFieldError} from 'src/hooks/ScrollToFieldError';
import useCategories from 'src/hooks/useCategories';
import {addProductPermission} from 'src/permissionsList';
import {isNullOrEmpty} from 'src/utils/helperMethods';
// form handling
import * as Yup from 'yup';
import Dropzone from '../../../components/inputs/dropzone';
// components
import Page from '../../../components/Page';
import {addProduct, addProductVariations} from '../../../utils/AdminApi';
import PropertiesSelectionList from './PropertiesSelectionList';
import {useProductPropertyToVariationHook} from './useProductPropertyToVariationHook';
import VariationsForm from './VariationsForm';
import AvailabilityRadioButtons from '../common/AvailabilityRadioButtons';
import {addProductSchema} from '../product.schema';
import {getNumber} from 'src/utils/numberUtils';
import DiscountPicker from '../common/DiscountPicker';

// ----------------------------------------------------------------------
export default function EcommerceShop() {
	const navigate = useNavigate();
	const {categories} = useCategories();
	const {
		variations,
		setVariations,
		allSiteProperties,
		selectedProperties,
		setProperties,
		setSelectedProperties,
		pvModalIsVisible,
		setPvModalVisibility,
		propertyToAddTo,
		setPropertyToAddTo,
		onNewPropertyValueAdded,
	} = useProductPropertyToVariationHook();

	const onSubmit = async (v) => {
		const {mainImage, images, ...product} = v;

		const {id: productId} = await toast.promise(
			addProduct(
				{
					...product,
					price: getNumber(product.price, 0),
					variations: variations.map((v) => ({
						...v,
						price: !isNullOrEmpty(v.price) ? v.price : null /* we want to store as null not 0 or "" */,

						discount: !isNullOrEmpty(v.discount)
							? v.discount
							: null /* we want to store as null not 0 or "" */,
					})),
				},
				mainImage,
				images,
			),
			{
				error: 'حدث خطا غير متوقع',
				success: 'تم انشاء المنتج بنجاح',
				pending: 'يرجي الانتظار',
			},
		);

		navigate('/products/' + productId);
	};

	return (
		<Page title="اضافة منتج">
			<AccessControlWrapper controllerAction={addProductPermission}>
				<Container maxWidth="md">
					<FormikWrapper
						hideSubmitButton
						values={initialValues}
						schema={addProductSchema}
						handleSubmit={onSubmit}
					>
						{({errors, touched, isSubmitting, values, getInputProps, setFieldTouched, setFieldValue}) => (
							<Stack sx={{mb: 2}} spacing={2}>
								<Stack spacing={1} direction="row" justifyContent="space-between">
									<Typography variant="h4" sx={{mb: 5}}>
										اضافة منتج
									</Typography>
									<PublishSwitch
										value={values.publish_at != null}
										onChange={(newValue) => setFieldValue('publish_at', newValue)}
									/>
								</Stack>
								<Stack spacing={3}>
									{/* Main info Card */}
									<Card sx={{p: 4, flex: 1}}>
										<Stack spacing={3}>
											<ScrollToFieldError />
											<Stack spacing={1} justifyContent="space-between">
												<ImageCropPicker
													maxSize={3}
													placeholder={'الصورة الرئيسية'}
													{...getInputProps('mainImage')}
													setFile={(file) => setFieldValue('mainImage', file)}
												/>
												<Stack flex={1} justifyContent={'space-between'} spacing={2}>
													<TextField fullWidth type="text" label="اسم المنتج" {...getInputProps('name')} />

													<TextField
														fullWidth
														type="text"
														minRows={3}
														multiline
														label="تفاصيل المنتج"
														{...getInputProps('details')}
													/>
												</Stack>
											</Stack>

											{categories.length > 0 && (
												<Picker
													multiple
													{...getInputProps('categories')}
													label="الفئة"
													options={categories}
													labelKey="name"
													valueKey="id"
													value={values.categories}
													onChange={(e) => setFieldValue('categories', e.target.value)}
												/>
											)}
											<AvailabilityRadioButtons
												value={values.infinite}
												onChange={(newValue) => setFieldValue('infinite', newValue)}
											/>

											<Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
												<Typography>هل هذا المنتج يحتوي علي اختيارات مثل المقاس واللون ؟</Typography>
												<SelectPropertyModal
													properties={allSiteProperties}
													setProperties={setProperties}
													onPropertiesSelected={(newProperties) =>
														setSelectedProperties([...selectedProperties, ...newProperties])
													}
													propertiesNotSelected={allSiteProperties.filter(
														(s) => !selectedProperties.find((sp) => sp.id === s.id),
													)}
												/>
											</Stack>

											<Divider />

											{selectedProperties.length === 0 && !values.infinite && (
												<Stack direction={{md: 'row'}} spacing={{xs: 1, md: 2}}>
													<TextField
														id="addedQuantity"
														name="addedQuantity"
														label="الكمية"
														autoComplete="off"
														sx={{flex: '1 1 auto'}}
														type="number"
														{...getInputProps('addedQuantity')}
													/>
													<TextField
														id="cost_per_unit"
														name="cost_per_unit"
														label="تكلفة القطعة"
														autoComplete="off"
														sx={{flex: '1 1 auto'}}
														type="number"
														{...getInputProps('cost_per_unit')}
													/>
												</Stack>
											)}

											<Stack spacing={2} direction="row" justifyContent="space-between">
												<TextField
													fullWidth
													type="text"
													inputMode="numeric"
													pattern="\d*"
													InputProps={{min: 0}}
													label="السعر"
													{...getInputProps('price')}
												/>
												<DiscountPicker
													sx={{maxWidth: 90}}
													name="discount"
													label="التخفيض"
													value={values.discount ?? 0}
													onChange={(discountValue) => {
														setFieldValue('discount', discountValue);
													}}
												/>
											</Stack>

											{selectedProperties.length > 0 && (
												<Typography variant="caption">
													سيتم استخدام هذا السعر في حالة عدم تحديد السعر لكل خيار
												</Typography>
											)}
										</Stack>
									</Card>

									{selectedProperties.length > 0 && (
										<Card sx={{p: 4, flex: 1}}>
											<PropertiesSelectionList
												allSiteProperties={allSiteProperties}
												selectedProperties={selectedProperties}
												setSelectedProperties={setSelectedProperties}
												setPvModalVisibility={setPvModalVisibility}
												setPropertyToAddTo={setPropertyToAddTo}
											/>

											{!isNullOrEmpty(variations) && (
												<VariationsForm variations={variations} setVariations={setVariations} values={values} />
											)}
										</Card>
									)}
									{/* Additional info card */}
									<Card sx={{p: 4, flex: 1}}>
										<Accordion>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												<Typography>معلومات اضافية</Typography>
											</AccordionSummary>

											<AccordionDetails>
												<Stack spacing={2}>
													<Typography>صور اضافية للمنتج</Typography>
													<Dropzone
														maxSize={1}
														allowMultiple
														selectedFiles={values.images}
														onFilesChanged={(img) => {
															setFieldValue('images', img);
														}}
														{...getInputProps('images')}
													/>
													<TextField fullWidth type="text" label="كود المنتج" {...getInputProps('sku')} />
													<TextField
														fullWidth
														type="text"
														inputMode="numeric"
														pattern="\d*"
														InputProps={{min: 0}}
														label="الوزن"
														placeholder="الوزن بالكيلو"
														{...getInputProps('weight')}
													/>
													<Stack spacing={1} direction="row" justifyContent="space-between">
														<TextField
															fullWidth
															InputProps={{min: 0}}
															pattern="\d*"
															type="text"
															inputMode="numeric"
															label="الطول"
															placeholder="الطول"
															{...getInputProps('width')}
														/>
														<TextField
															fullWidth
															InputProps={{min: 0}}
															pattern="\d*"
															type="text"
															inputMode="numeric"
															label="العرض"
															placeholder="العرض"
															{...getInputProps('height')}
														/>
														<TextField
															fullWidth
															InputProps={{min: 0}}
															pattern="\d*"
															type="text"
															inputMode="numeric"
															label="الارتفاع"
															placeholder="الارتفاع"
															{...getInputProps('_length')}
														/>
													</Stack>

													<CustomFields
														title="جدول المواصفات "
														errors={errors.attrs}
														touches={touched.attrs}
														value={values.attrs}
														onChange={(newValue) => setFieldValue('attrs', newValue)}
														setFieldTouched={setFieldTouched}
													/>
												</Stack>
											</AccordionDetails>
										</Accordion>
									</Card>
								</Stack>
								<SubmitButton isSubmitting={isSubmitting}>اضافة</SubmitButton>
							</Stack>
						)}
					</FormikWrapper>

					{/* <ProductCartWidget /> */}
				</Container>
				<AddPropertyValueModal
					isVisible={pvModalIsVisible}
					setVisibility={setPvModalVisibility}
					propertyId={propertyToAddTo}
					onCreate={onNewPropertyValueAdded}
				/>
			</AccessControlWrapper>
		</Page>
	);
}

const initialValues = {
	sku: '',
	name: '',
	details: '',
	price: '',
	quantity: 0,
	categories: [],
	infinite: false,
	images: [],
	attrs: [],
	mainImage: null,
	publish_at: new Date(),
};
